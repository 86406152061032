<template>
  <v-card class="pa-8">
    <header-content :pageTitle="'Edit Data Pengajuan Pinjaman'" />

    <div>
      <!-- <h4 class="pb-4">Excel Input</h4>
      <VueFileAgent
        :theme="'list'"
        :deletable="true"
        :accept="
          '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        "
        :helpText="'Pilih file excel yang ingin diupload'"
        v-model="uploadedFile"
      ></VueFileAgent>
      <button-form :submit="submitLoan" :buttonText="'Upload'" class="pt-8" />

      <v-divider class="my-6" /> -->

      <h4 class="pb-4">Form Input</h4>
      <form-loan :formLoan="formNewLoan" />
      <button-form :submit="submitLoan" :loading="loading" class="pt-8" />
    </div>

    <popup-success
      :dialog="showSuccessDialog"
      :closeDialog="closeSuccessDialog"
    />
    <popup-error :dialog="showErrDialog" :closeDialog="closeErrDialog" />
  </v-card>
</template>

<script>
import store from "../../store/index.js";
import converters from "../../helpers/converters";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import StepperUser from "../../components/Stepper/StepperUser.vue";
import PopupRole from "../../components/Popup/PopupRole.vue";
import ButtonForm from "../../components/Button/ButtonForm.vue";
import FormLoan from "../../components/Form/FormLoanEdit.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";
import PopupError from "../../components/Popup/PopupError.vue";

export default {
  components: {
    HeaderContent,
    StepperUser,
    PopupRole,
    PopupSuccess,
    PopupError,
    ButtonForm,
    FormLoan,
  },

  data() {
    return {
      steps: 1,
      uploadedFile: "",
      showDialog: false,
      showSuccessDialog: false,
      showErrDialog: false,
      loading: false,
      formNewLoan: {
        penyelenggara: "",
        idPinjaman: "",
        borrower: null,
        idPinjamanOld: "",
        namaPinjaman: "",
        idSyariah: "",
        ratingPengajuanPinjaman: "",
        nilaiPengajuanPinjaman: "",
        nilaiPermohonanPinjaman: "",
        jangkaWaktuPinjaman: "",
        statusPengajuanPinjaman: null,
        sukuBungaPinjaman: "",
        tanggalMulaiPendanaan: "",
        tanggalPengajuanPinjaman: "",
        penggunaanPinjaman: null,
        agunan: "",
        jenisAgunan: null,
        permintaanPinjaman: "",
        rasioPinjamanNilaiAgunan: "",
        rasioPinjamanAset: "",
        cicilanBulan: "",
        ratioPengajuanPinjaman: "",
        nilaiPlafond: "",
        satuanSukuBungaPinjaman: "",
        jenisBunga: "",
        tanggalMulaiPublikasiPinjaman: "",
        rencanaJangkaWaktuPublikasi: "",
        realisasiJangkaWaktuPublikasi: "",
        frekuensiPinjaman: "",
        report: null,
      },
    };
  },

  async mounted() {
    await store.dispatch("options/fetchLoanApplications");
    await store.dispatch("office/fetchLoan", this.$route.params.id);
    this.formNewLoan = {
      penyelenggara: store.state.office.currentLoan.id_penyelenggara,
      idPinjaman: store.state.office.currentLoan.id_pinjaman,
      borrower: store.state.office.currentLoan.id_borrower,
      idPinjamanOld: store.state.office.currentLoan.id_pinjaman_old,
      namaPinjaman: store.state.office.currentLoan.nama_pinjaman,
      idSyariah: store.state.office.currentLoan.id_syariah,
      ratingPengajuanPinjaman:
        store.state.office.currentLoan.rating_pengajuan_pinjaman,
      nilaiPengajuanPinjaman:
        store.state.office.currentLoan.nilai_pengajuan_pinjaman,
      nilaiPermohonanPinjaman:
        store.state.office.currentLoan.nilai_permohonan_pinjaman,
      jangkaWaktuPinjaman: store.state.office.currentLoan.jangka_waktu_pinjaman,
      statusPengajuanPinjaman:
        store.state.office.currentLoan.id_status_pengajuan_pinjaman,
      sukuBungaPinjaman: store.state.office.currentLoan.suku_bunga_pinjaman,
      tanggalMulaiPendanaan: store.state.office.currentLoan.tgl_mulai_pendanaan.substring(
        0,
        10
      ),
      tanggalPengajuanPinjaman: store.state.office.currentLoan.tgl_pengajuan_pinjaman.substring(
        0,
        10
      ),
      penggunaanPinjaman: store.state.office.currentLoan.penggunaan_pinjaman,
      agunan: store.state.office.currentLoan.agunan,
      jenisAgunan: store.state.office.currentLoan.jenis_agunan,
      permintaanPinjaman: store.state.office.currentLoan.permintaan_pinjaman,
      rasioPinjamanNilaiAgunan:
        store.state.office.currentLoan.rasio_pinjaman_nilai_agunan,
      rasioPinjamanAset: store.state.office.currentLoan.rasio_pinjaman_aset,
      cicilanBulan: store.state.office.currentLoan.cicilan_bulan,
      ratioPengajuanPinjaman:
        store.state.office.currentLoan.ratio_pengajuan_pinjaman,
      nilaiPlafond: store.state.office.currentLoan.nilai_plafond,
      satuanSukuBungaPinjaman:
        store.state.office.currentLoan.satuan_suku_bunga_pinjaman,
      jenisBunga: store.state.office.currentLoan.jenis_bunga,
      tanggalMulaiPublikasiPinjaman: converters.shortDateInput(
        store.state.office.currentLoan.tgl_mulai_publikasi_pinjaman
      ),
      rencanaJangkaWaktuPublikasi:
        store.state.office.currentLoan.rencana_jangka_waktu_publikasi,
      realisasiJangkaWaktuPublikasi:
        store.state.office.currentLoan.realisasi_jangka_waktu_publikasi,
      frekuensiPinjaman: store.state.office.currentLoan.frekuensi_pinjaman,
    };
  },

  methods: {
    closeSuccessDialog() {
      this.showSuccessDialog = !this.showSuccessDialog;
      this.$router.push("/list-submission");
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },

    async submitLoan() {
      this.uploadedFile = "";
      this.loading = true;
      try {
        console.log(this.formNewLoan);
        await store
          .dispatch("office/editLoan", {
            id: this.$route.params.id,
            data: this.formNewLoan,
          })
          .then((r) => {
            if (r) {
              console.log(r, "sukses");
              this.showSuccessDialog = true;
              this.loading = false;
            } else {
              this.showErrDialog = true;
              this.loading = false;
            }
          });

        this.showSuccessDialog = true;
      } catch (err) {
        this.showErrDialog = true;
        this.loading = false;
      }
    },
  },
};
</script>
